import ReactPlayerLoader from '@brightcove/react-player-loader'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import styles from './video.module.scss'

import Seo from '@/components/general/seo'
import Layout from '@/components/layout/layout'

import { Navigation } from '@/components/video/navigation'
import ViewingEnv from '@/components/video/viewing-env'
import { ACCOUNT_ID } from '@/lib/constants'
import { BreadcrumbContext } from '@/types/breadcrumb'

const Page: React.FC<BreadcrumbContext> = ({ pageContext }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext
  return (
    <Layout>
      <Seo title="双極II型障害における認知機能" />
      <Helmet>
        {/* <!-- Ptengine Tag --> */}
        <script src="https://js.ptengine.jp/67a5keif.js"></script>
        {/* End Ptengine Tag */}
      </Helmet>
      <div className={styles.wrap}>
        <h1 className={styles.title}>第14回 双極II型障害における認知機能</h1>
        <ReactPlayerLoader
          attrs={{ className: styles.video }}
          accountId={ACCOUNT_ID}
          videoId="6320900758112"
          playerId="hM1SBfBel"
          embedOptions={{ responsive: 'aspectRatio' }}
        />
        <div className={styles.videoBody}>
          <div className={styles.speaker}>
            <p>
              <span>ファシリテーター</span>
              <span>Lakshmi N. Yatham教授 (ブリティッシュコロンビア大学)</span>
            </p>
            <p>
              <span>エキスパート</span>
              <span>Katherine Burick准教授 (ハーバード大学)</span>
            </p>
          </div>
          <Navigation prev="2" next="7" />
          <div className={styles.paragraph}>
            <p>
              双極II型障害における認知機能障害の特徴とその対処法について学ぶことが出来ます。
              <br />
            </p>
            <div>
              <span>＜学びのポイント＞</span>
              <ul>
                <li>
                  双極II型障害における認知機能障害の特徴、双極I型障害との違い
                </li>
                <li>認知機能障害が生じるメカニズム</li>
                <li>認知機能障害の評価法と対処法</li>
              </ul>
            </div>
            <p>大日本住友製薬（現：住友ファーマ株式会社）</p>
          </div>
          <ViewingEnv />
          <Breadcrumb
            crumbs={crumbs}
            crumbSeparator=" > "
            crumbLabel={'双極II型障害における認知機能'}
          />
        </div>
      </div>
    </Layout>
  )
}

export default Page
